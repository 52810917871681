@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: normal;
    src: url("/public/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
    font-family: "MontserratBlack";
    font-style: normal;
    font-weight: normal;
    src: url("/public/assets/fonts/Montserrat-Black.ttf");
}

@font-face {
    font-family: "BebasNeue";
    font-style: normal;
    font-weight: normal;
    src: url("/public/assets/fonts/BebasNeue-Regular.otf");
}




.ion-page {
    

}