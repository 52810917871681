ion-header.floatingheader{
    position: absolute;
    top: 1%;
    height: 10%;
    background-color: #222;
    width: 95%;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.headerlogotracking{
    width: 100% !important;
    top:32%;
    left:0px;
    position: relative;
    filter:invert();
}
ion-button.backbutton {
    font-family: 'MontserratBlack';
    font-size: 20px;
    left: -35px;
    position: relative;
    display: flex;
}
ion-button.refreshbutton {
    font-family: 'MontserratBlack';
    font-size: 20px;
    left:25px;
    position: relative;
    display: flex;
}
.bottomsheet {
    height: 35%;
    position: absolute;
    left: -12.5px;
    bottom: -25px;
    width: 100%;
    z-index: 100;
    align-self: center;
    display: flex;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    flex-direction: column;
}
.bottomsheet ion-row{
    background-color: transparent;
    box-shadow: 0px 0px 0px rgba(0,0,0,0);
}
.rowitemcard{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    box-shadow: 0px 0px 0px rgba(0,0,0,0);
    background-color: #fff !important;
    --highlight-height: 2px;
    --highlight-color-focused: #5e5e5e;
    border-radius: 0px;
    border-bottom: 1px solid silver;

}
.avatar{
    max-width:20%;
    display: flex;
    justify-content: center;
}
.rowitemcard ion-col.driver {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right:50px;
}

ion-label{
    font-family: 'MontSerrat';
    font-size: 14px;
    text-align: left;
    display:flex;
    justify-content: flex-start;
    color: #666;
}
.buttongroup{
    display: inline-flex;
    padding:5px;
    height: 60px;
    background-color: #fff;
    border-radius: 15px;
    margin-left: 15px;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}
.buttonleft, .buttonright {
    width: 100% !important;
    height: 90%;
    text-align: center;
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #444;
    margin: 0.5px;
    font-size: 20px;
}
.buttonleft{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.buttonright{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.fleetinfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    position: absolute;
    top: 35%;
    width: 90%;
    align-self: center;
}
.fleetinfo ion-label{
    font-size: 14px;

}
ion-avatar {
    border: 2px solid var(--ion-color-danger);
    padding: 2px;
}


@media screen and (min-width: 360px) {
    ion-img.headerlogotracking{
        width: 100% !important;
        top:1vh !important;
        left:0px;
        position: relative;
    }
}

@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    ion-img.headerlogotracking{
        width: 100% !important;
        top:30% !important;
        left:0px;
        position: relative;
    }
}