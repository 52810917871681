html{
    text-align: center;
    justify-content: center;
}

.ion-page {
    --ion-background-color : #fff;
    max-width: 320px;
    text-align: center;
    justify-content: center;
    align-self: center;
    left: 0px !important;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    contain: layout size style;
    overflow: hidden;
    z-index: 0;
    margin: 0 auto;
}

ion-text{
    color:#222;
}
ion-img.typelogo{
    width: 50% !important;
    left:15px;
    position: relative;
}
ion-card{
    background-color: #fff;
    color:#222;
    border-radius: 15px;
    box-shadow:0px 5px 25px rgba(0,0,0,0.1);
}
ion-card ion-img{
    width: 100% !important;
}
ion-img.button-icon{
    width: 8% !important;
}

ion-button{
    font-family: 'MontserratBlack';
    font-size: 20px;
    text-align: left;
    align-items: start;
    justify-items: start;
}

.rowcard{
    background-color: #fff;
    box-shadow: 0px 5px 25px rgba(0,0,0,0.1);
    width: 92%;
    margin:10px 10px 30px 10px;
    border-radius: 15px;
}
.wabackground {
    padding: 10px;
    background-color: #222;
    border-radius: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
}
ion-img.walogo {
    width: 60px;
    border: 2px solid red;
    display: block;
    padding: 10px;
    border-radius: 1000px;
}
.walabel {
    justify-content: center;
    display: flex;
    text-align: center;
    align-self: center;
    color:#222;
}
.trackingbutton{
    font-family: 'MontserratBlack';
    text-align: left;
    font-size: 20px;
    text-align: left;
    padding:20px 20px 20px 30px;
}
.trackingicon{
    position: relative;
    top:3px;
    right:10px;
}

a{
    text-decoration:none;
}

h4{
    font-family: 'MontserratBlack';
    text-align: left;
}

